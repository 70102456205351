import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useTranslation } from "../../../i18n";
import { Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { Breadcrumbs, Link } from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";

export default function Settings() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const location = useLocation();
  let currentTab = location.pathname.split("/").pop();
  if (currentTab === "settings") {
    currentTab = "general";
  }

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Link
          component={RouterLink}
          color="neutral"
          to={`/${teamCtx.team.slug}`}
        >
          {teamCtx.team.name}
        </Link>
        <Typography level="title-sm">{t("Settings")}</Typography>
      </Breadcrumbs>
      <Tabs
        sx={{
          bgcolor: "transparent",
        }}
        value={currentTab}
      >
        <TabList
          variant="outlined"
          disableUnderline
          sx={{
            m: 2,
            mt: 0,
            p: 0.5,
            gap: 0.5,
            borderRadius: "md",
            bgcolor: "background.level2",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "1px 1px 1px #000000",
              borderRadius: "md",
              bgcolor: "background.surface",
            },
            [`& .${tabClasses.root}:hover`]: {
              borderRadius: "md",
              bgcolor: "background.level3",
              boxShadow: "1px 1px 1px rgb(40, 40, 40)",
            },
          }}
        >
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"general"}
            value="general"
          >
            {t("General")}
          </Tab>
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"members"}
            value="members"
          >
            {t("Members")}
          </Tab>
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"integrations"}
            value="integrations"
          >
            {t("Integrations")}
          </Tab>
        </TabList>
        <Outlet context={teamCtx} />
      </Tabs>
    </>
  );
}
