import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton, { MenuButtonProps } from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { useTranslation, useLang } from "../../i18n";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import TranslateIcon from "@mui/icons-material/Translate";

const languages = [
  {
    primary: "Chinese",
    secondary: "Simplified",
    lang: "zh-CN",
  },
  {
    primary: "Chinese",
    secondary: "Traditional",
    lang: "zh-TW",
  },
  {
    primary: "English",
    secondary: "American",
    lang: "en-US",
  },
  {
    primary: "Russian",
    secondary: null,
    lang: "ru-RU",
  },
  {
    primary: "Spanish",
    secondary: null,
    lang: "es-MX",
  },
  {
    primary: "Japanese",
    secondary: null,
    lang: "ja-JP",
  },
];

function langToEmoji(lang: string) {
  const countryCode = lang.split("-")[1];
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export default function LanguageToggle(props: MenuButtonProps) {
  const { onClick, ...other } = props;
  const t = useTranslation();
  const [lang, setLang] = useLang();
  const language = languages.find((language) => language.lang === lang);

  return (
    <Dropdown>
      <MenuButton
        size="sm"
        fullWidth
        sx={{
          // maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "var(--joy-radius-sm)",
          justifyContent: "flex-start",
          overflow: "hidden",
        }}
        endDecorator={
          <Box sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}>
            <ArrowDropDown fontSize="medium" />
          </Box>
        }
        // sx={{
        //   maxWidth: "32px",
        //   maxHeight: "32px",
        //   borderRadius: "50%",
        // }}
        {...other}
      >
        <Box sx={{ display: { xs: "none", sm: "flex", lg: "none" } }}>
          <TranslateIcon />
        </Box>
        <Typography
          fontSize="small"
          sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
          startDecorator={<TranslateIcon />}
        >
          {language?.primary && t(language?.primary)}
          {language?.secondary && ` (${t(language?.secondary)})`}
        </Typography>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "99999",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        {languages.map((option) => (
          <MenuItem
            key={option.lang}
            selected={lang === option.lang}
            onClick={() => {
              setLang(option.lang);
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography fontSize="large">
                {langToEmoji(option.lang)}
              </Typography>
              <Box sx={{ ml: 1.5 }}>
                <Typography level="title-sm" textColor="text.primary">
                  {t(option.primary)}
                </Typography>
                {option.secondary && (
                  <Typography level="body-xs" textColor="text.tertiary">
                    {t(option.secondary)}
                  </Typography>
                )}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
