import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";
import {
  useUserBySlugQuery,
  useUserMeQuery,
} from "../../__generated__/types-and-hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../i18n";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import DOMPurify from "dompurify";
import TeamLinkButton from "../dash/organizations/TeamLinkButton";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FileUploadButton from "../../components/FileUploadButton";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

export default function UserProfile() {
  const t = useTranslation();
  const navigate = useNavigate();

  const { slug } = useParams();
  const userBySlugQuery = useUserBySlugQuery({ variables: { slug: slug! } });
  const user = userBySlugQuery.data?.user_by_slug;
  const userMeQuery = useUserMeQuery();
  const userMe = userMeQuery.data?.user_me;
  const editable = userMe?.id === user?.id;

  return (
    <>
      <Box sx={{ padding: 4, maxWidth: 900, margin: "0 auto" }}>
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: 180,
              width: "100%",
            }}
          >
            <img
              src={
                user?.background
                  ? process.env.REACT_APP_API_URL + "/" + user?.background
                  : "/images/inspections_empty_state.png"
              }
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
            {editable && (
              <IconButton
                component={"label"}
                variant="soft"
                color="neutral"
                sx={{
                  border: 1,
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <>
                  <FileUploadButton
                    entityType="user"
                    resource="background"
                    onSuccess={userMeQuery.refetch}
                  />
                  <FileUploadRoundedIcon />
                </>
              </IconButton>
            )}
          </Box>
          <Avatar
            sx={{
              position: "absolute",
              width: 160,
              height: 160,
              marginBottom: 2,
              boxShadow: "lg",
              marginTop: 0,
              top: 90,
              border: 2,
              borderColor: "var(--joy-palette-background-level1)",
            }}
            src={
              user?.picture
                ? process.env.REACT_APP_API_URL + "/" + user?.picture
                : "/images/qcsku_light.jpg"
            }
            alt={t("User Avatar")}
          />
          {editable && (
            <IconButton
              component={"label"}
              variant="solid"
              color="neutral"
              sx={{
                position: "absolute",
                top: 190,
                ml: 25,
              }}
            >
              <>
                <FileUploadButton
                  entityType="user"
                  resource="picture"
                  onSuccess={userMeQuery.refetch}
                />
                <FileUploadRoundedIcon />
              </>
            </IconButton>
          )}

          <Stack
            gap={1}
            sx={{
              width: "100%",
              p: 2,
              pt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              level="h2"
              endDecorator={
                <>
                  {user?.verifiedEmail && (
                    <VerifiedOutlinedIcon color="primary" />
                  )}
                  {editable && (
                    <IconButton
                      variant="soft"
                      color="primary"
                      onClick={() => navigate("./edit-name")}
                    >
                      <CreateOutlinedIcon />
                    </IconButton>
                  )}
                </>
              }
            >
              {user?.firstName && user.firstName.length > 0
                ? user.firstName
                : t("[First Name]")}{" "}
              {user?.lastName && user.lastName.length > 0
                ? user.lastName
                : t("[Last Name]")}{" "}
            </Typography>

            <Box>
              {user?.teamEdges.map((teamEdge) => (
                <TeamLinkButton
                  key={teamEdge.team.id}
                  name={teamEdge.team.name}
                  slug={teamEdge.team.slug}
                  picture={teamEdge.team.picture}
                />
              ))}
            </Box>
          </Stack>

          {/* About Section */}
          {user && user.bio && (
            <>
              <Divider />
              <Box
                sx={{
                  position: "relative",
                  alignItems: "left",
                  width: "100%",
                  p: 3,
                }}
              >
                <Typography
                  sx={{ marginTop: 1 }}
                  endDecorator={
                    editable && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                        variant="soft"
                        color="primary"
                        onClick={() => navigate("./edit-about")}
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                    )
                  }
                >
                  <div
                    className="quill-content"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(user.bio),
                    }}
                  />
                </Typography>
              </Box>
            </>
          )}
        </Card>
      </Box>
      <Outlet />
    </>
  );
}
