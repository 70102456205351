import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  Stack,
  Typography,
} from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import {
  useUserMeQuery,
  useUserSendVerificationEmailMutation,
} from "../../__generated__/types-and-hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { useCallback } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TeamLinkButton from "./organizations/TeamLinkButton";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/joy/Link";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LanguageToggle from "../../components/header/LanguageToggle";
import DashboardIcon from "@mui/icons-material/Dashboard";

export default function Dashboard() {
  const navigate = useNavigate();
  const t = useTranslation();
  const [userSendVerificationEmailMutation, userSendVerificationEmailRes] =
    useUserSendVerificationEmailMutation({});

  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;
  const handleGetStarted = useCallback(() => {
    navigate("/get-started");
  }, [navigate]);

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Typography level="title-sm">Dashboard</Typography>
      </Breadcrumbs>

      <Box sx={{ p: { sm: 2, xs: 0 }, maxWidth: 900, margin: "0 auto" }}>
        {/* Verify Email */}
        {!user?.verifiedEmail && !userSendVerificationEmailRes.data && (
          <Alert
            sx={{ marginBottom: 3 }}
            startDecorator={<WarningIcon fontSize="medium" />}
            variant="outlined"
            color="warning"
            endDecorator={
              <Button
                disabled={userSendVerificationEmailRes.loading}
                color="success"
                sx={{ mr: 1 }}
                startDecorator={<EmailOutlinedIcon />}
                onClick={() => userSendVerificationEmailMutation()}
              >
                {t("Send the link")}
              </Button>
            }
          >
            {t("Please verify your email by opening a verification link.")}
          </Alert>
        )}
        {user?.teamEdges && user?.teamEdges.length === 0 && (
          <Card sx={{ marginBottom: 3 }}>
            <Typography level="title-lg">
              {t("Get Started with QC Collaboration")}
            </Typography>
            <CardContent>
              {t(`To begin, join an existing brand or agency, or create a new one.
              Brands can request inspections for SKUs, while agencies respond
              with pricing and availability. Once set up, you'll be able to
              manage bids, track inspections, and communicate seamlessly with
              partners.`)}
            </CardContent>
            <Button variant="solid" onClick={handleGetStarted}>
              {t("Get Started")}
            </Button>
          </Card>
        )}
        <Card>
          <Typography level="title-lg">{t("Quick Actions")}</Typography>
          <Divider />
          <CardContent>
            <Typography level="body-sm">{t("Brands & Agencies")}</Typography>
            <Stack direction={{ xs: "column", sm: "row" }} gap={1}>
              {user?.teamEdges.map((teamEdge) => (
                <TeamLinkButton
                  key={teamEdge.team.id}
                  name={teamEdge.team.name}
                  slug={teamEdge.team.slug}
                  picture={teamEdge.team.picture}
                />
              ))}
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              startDecorator={<AddIcon />}
              variant="outlined"
              onClick={() => navigate("./create-brand")}
            >
              {t("Brand")}
            </Button>
            <Button
              startDecorator={<AddIcon />}
              variant="outlined"
              onClick={() => navigate("./create-agency")}
            >
              {t("Agency")}
            </Button>
            <Button
              startDecorator={<GroupOutlinedIcon />}
              variant="outlined"
              onClick={() => navigate("./join")}
            >
              {t("Join")}
            </Button>
          </CardActions>
          <Divider />
          <CardContent>
            <Typography level="body-sm">{t("Management")}</Typography>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <Link
                component={RouterLink}
                to={"/api-keys"}
                sx={{ p: 1, ml: 0, mr: 2, borderRadius: 8 }}
                startDecorator={<VpnKeyOutlinedIcon />}
              >
                {t("API Keys")}
              </Link>
              <Link
                component={RouterLink}
                to={"/org"}
                sx={{ p: 1, ml: 0, mr: 2, borderRadius: 8 }}
                startDecorator={<GroupsOutlinedIcon />}
              >
                {t("Membership")}
              </Link>
              <Link
                component={RouterLink}
                to={`/user/${user?.slug}`}
                sx={{ p: 1, ml: 0, mr: 2, borderRadius: 8 }}
                startDecorator={<PersonIcon />}
              >
                {t("Profile")}
              </Link>
            </Stack>
          </CardContent>
          <Divider />
          <CardContent>
            <Typography level="body-sm">{t("Preferences")}</Typography>
            <Stack direction={{ xs: "column", sm: "row" }} gap={1}>
              <List
                size="sm"
                sx={{
                  flexGrow: 0,
                }}
              >
                <LanguageToggle />
              </List>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Outlet />
    </>
  );
}
