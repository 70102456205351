import { BehaviorSubject } from "rxjs";

export class FiltersVM {
  public serviceType$ = new BehaviorSubject<string[]>([]);
  public status$ = new BehaviorSubject<string[]>([]);
  public visibility$ = new BehaviorSubject<string[]>([]);

  reset() {
    this.serviceType$.next([]);
    this.status$.next([]);
    this.visibility$.next([]);
  }

  apply() {}
}
