import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import { ListItemContent, ListItemDecorator, Typography } from "@mui/joy";
import {
  AgencySearchFragment,
  useAgencyListQuery,
} from "../__generated__/types-and-hooks";
import SearchIcon from "@mui/icons-material/Search";

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      <ListItemDecorator>
        <Typography level="body-xs">#{dataSet[1].id}</Typography>
      </ListItemDecorator>
      <ListItemContent sx={{ fontSize: "sm" }}>
        {dataSet[1]?.name}
        <Typography level="body-xs">{dataSet[1].name}</Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 1000 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface QAgencyProps {
  hasFocus?: boolean;
  onChange: (agency: AgencySearchFragment | null) => void;
  agency: { id: number; name: string } | null;
}

export function QAgency({ hasFocus = false, onChange, agency }: QAgencyProps) {
  const agencyListQuery = useAgencyListQuery({});
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);

  const handleChanges = React.useCallback(
    (e: any, value: AgencySearchFragment | null) => {
      onChange(value);
    },
    [onChange]
  );

  const isLoading = agencyListQuery.loading;
  const agencies = agencyListQuery.data?.team_list?.teams;

  return (
    <Autocomplete
      size="sm"
      sx={{ minWidth: "250px", width: "100%", fontSize: "14px" }}
      value={agencies?.find((s) => s.id === agency?.id) ?? agency}
      loading={isLoading}
      disableListWrap
      startDecorator={<SearchIcon />}
      placeholder="Agencies..."
      onChange={handleChanges as any}
      slotProps={{ input: { ref: inputRef } }}
      slots={{
        listbox: ListboxComponent,
      }}
      options={agencies ?? []}
      groupBy={(option) => option.name.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
  );
}
