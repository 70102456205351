import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import {
  CircularProgress,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import {
  Supplier,
  useAutocompleteSuppliersQuery,
} from "../__generated__/types-and-hooks";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "../i18n";

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      <ListItemDecorator>
        <Typography level="body-xs">#{dataSet[1].id}</Typography>
      </ListItemDecorator>
      <ListItemContent sx={{ fontSize: "sm" }}>
        {dataSet[1]?.name}
        <Typography level="body-xs">
          {dataSet[1].city} {dataSet[1].state} {dataSet[1].postal_code}{" "}
          {dataSet[1].country}
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 1000 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface QSupplierProps {
  hasFocus?: boolean;
  teamId: number;
  supplier: { id: number; name: string } | null;
  update: (supplier: Supplier | null) => Promise<string | null | undefined>;
}

export default function QSupplier({
  hasFocus = false,
  supplier,
  teamId,
  update,
}: QSupplierProps) {
  const t = useTranslation();
  const [intVal, setIntVal] = React.useState(supplier);
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setIntVal(supplier);
  }, [supplier]);

  React.useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);

  const supplierListQuery = useAutocompleteSuppliersQuery({
    variables: { teamId },
  });

  const handleChanges = React.useCallback(
    async (e: any, value: Supplier | null) => {
      inputRef.current?.blur();
      setLoading(true);
      setIntVal(value);
      await update(value);
      // setIntErr(err);
      setLoading(false);
    },
    [update]
  );

  const isLoading = supplierListQuery.loading;
  const suppliers = supplierListQuery.data?.supplier_list?.suppliers;

  return (
    <Autocomplete
      size="sm"
      sx={{ minWidth: "250px", width: "100%", fontSize: "14px" }}
      value={suppliers?.find((s) => s.id === intVal?.id) ?? intVal}
      loading={isLoading || loading}
      disableListWrap
      startDecorator={<SearchIcon />}
      placeholder={t("Suppliers...")}
      onChange={handleChanges as any}
      slotProps={{
        input: {
          ref: inputRef,
        },
      }}
      endDecorator={loading && <CircularProgress size="sm" value={25} />}
      slots={{
        listbox: ListboxComponent,
      }}
      options={suppliers ?? []}
      groupBy={(option) => option.name.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
  );
}

export interface MultiSupplierAutocompleteProps {
  hasFocus?: boolean;
  teamId: number;
  suppliers: { id: number; name: string }[] | null;
  onChange: (suppliers: Supplier[]) => void;
}

export function MultiSupplierAutocomplete({
  hasFocus = false,
  suppliers,
  teamId,
  onChange,
}: MultiSupplierAutocompleteProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);
  const supplierListQuery = useAutocompleteSuppliersQuery({
    variables: { teamId },
  });

  const handleChanges = React.useCallback(
    (e: any, value: Supplier[]) => {
      onChange(value);
    },
    [onChange]
  );

  const isLoading = supplierListQuery.loading;
  const suppliersData = supplierListQuery.data?.supplier_list?.suppliers;

  return (
    <Autocomplete
      multiple
      size="sm"
      // sx={{ minWidth: "250px", width: "100%", fontSize: "14px", border: 0 }}
      value={suppliers ?? []}
      loading={isLoading}
      disableListWrap
      startDecorator={<SearchIcon />}
      placeholder="Supplier..."
      onChange={handleChanges as any}
      slotProps={{
        input: {
          ref: inputRef,
        },
      }}
      slots={{
        listbox: ListboxComponent,
      }}
      options={suppliersData ?? []}
      groupBy={(option) => option.name.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
  );
}
