import { FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { Observable, Subject } from "rxjs";
import { ReactNode, useCallback } from "react";
import { useTranslation } from "../i18n";
import {
  PSIDescription,
  PSIIcon,
  PSILabel,
  PSIValue,
} from "../components/inspections/PSI";
import {
  InlineDescription,
  InlineIcon,
  InlineLabel,
  InlineValue,
} from "../components/inspections/Inline";
import {
  FADescription,
  FAIcon,
  FALabel,
  FAValue,
} from "../components/inspections/FA";
import { useMemo } from "react";
import { useRxVal } from "../hooks/useRx";
import IconsRadio from "../components/IconsRadio";

export function useServiceTypeOptions() {
  const t = useTranslation();
  const serviceTypeOptions = useMemo(
    () => [
      {
        label: t(PSILabel),
        description: t(PSIDescription),
        value: PSIValue,
        icon: <PSIIcon />,
      },
      {
        label: t(InlineLabel),
        description: t(InlineDescription),
        value: InlineValue,
        icon: <InlineIcon />,
      },
      {
        label: t(FALabel),
        value: FAValue,
        description: t(FADescription),
        icon: <FAIcon />,
      },
    ],
    [t]
  );
  return serviceTypeOptions;
}

export interface ServiceTypeFormProps {
  serviceType$: Subject<string>;
}

export interface XServiceType$Props {
  label?: string | ReactNode;
  val$: Subject<string>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XServiceType({
  val$,
  error$,
  showError,
  label,
  required,
}: XServiceType$Props) {
  const val = useRxVal(val$);
  const error = useRxVal(error$);

  const serviceTypeOptions = useServiceTypeOptions();
  const handleOnChange = useCallback(
    (arg: React.ChangeEvent<HTMLInputElement>) => {
      val$.next(arg.target.value);
    },
    [val$]
  );

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <IconsRadio
        name="service_type"
        options={serviceTypeOptions}
        value={val}
        onChange={handleOnChange}
      />
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
