import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import { ReactNode, useEffect, useState, useCallback, useRef } from "react";
import { SxProps } from "@mui/joy/styles/types";

export interface QNumberInputProps {
  label?: string | ReactNode;
  readonly?: boolean;
  val: number | null;
  error?: string | null;
  showError?: boolean;
  required?: boolean;
  containerSx?: SxProps;
  min?: number;
  max?: number;
  update: (val: number | null) => Promise<string | null | undefined>;
}

export default function QNumberInput({
  val,
  readonly,
  error,
  showError,
  label,
  required,
  containerSx,
  min = 1,
  max = 100,
  update,
  ...props
}: QNumberInputProps) {
  const [intVal, setIntVal] = useState(val);
  const [intErr, setIntErr] = useState<string | null | undefined>(error);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => setIntVal(val), [val]);
  useEffect(() => setIntErr(error), [error]);

  const debouncedUpdate = useCallback(
    (newVal: number | null) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        setLoading(true);
        const err = await update(newVal);
        setIntErr(err);
        setLoading(false);
      }, 500); // Reduced debounce delay for better UX
    },
    [update]
  );

  const validateNumber = (value: string): number | null => {
    const num = parseInt(value, 10);
    if (isNaN(num)) return null;
    return Math.min(Math.max(num, min), max); // Enforce min/max
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = validateNumber(e.target.value);
    setIntVal(newValue);
    debouncedUpdate(newValue);
  };

  const handleBlur = async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(true);
    const err = await update(intVal);
    setIntErr(err);
    setLoading(false);
  };

  return (
    <FormControl
      required={required}
      error={showError && !!intErr}
      sx={containerSx}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {readonly ? (
        <Typography fontSize="medium">{intVal}</Typography>
      ) : (
        <Input
          {...props}
          type="number"
          value={intVal ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          slotProps={{
            input: { min, max, step: 1 },
          }}
          endDecorator={loading && <CircularProgress size="sm" />}
        />
      )}
      {showError && intErr && <FormHelperText>{intErr}</FormHelperText>}
    </FormControl>
  );
}
