import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputTypeMap,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import React, { ReactNode, useEffect, useState } from "react";
import { useLang, useTranslation } from "../i18n";

const formatValue = (number: number, currency: string, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number || 0);
};

export interface QMoneyInputProps {
  label?: string | ReactNode;
  val: [number, string];
  error?: string | null;
  showError?: boolean;
  required?: boolean;
  readonly?: boolean;
  update: (val: [number, string] | null) => Promise<string | null | undefined>;
}

export function QMoneyInput({
  val,
  error,
  showError,
  label,
  required,
  readonly,
  update,
  ...props
}: InputTypeMap<{}, "div">["props"] & QMoneyInputProps) {
  const t = useTranslation();
  const [lang] = useLang();

  const [intVal, setIntVal] = useState(val);
  const [intErr, setIntErr] = useState<string | null | undefined>(error);
  const [loading, setLoading] = useState(false);
  const onChange = async (newVal: [number, string] | null) => {
    setLoading(true);
    const err = await update(newVal);
    setIntErr(err);
    setLoading(false);
  };

  const handleChanges = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const parsedValue = inputValue === "" ? 0 : parseInt(inputValue, 10);
    setIntVal([parsedValue, intVal[1]]);
    onChange([parsedValue, intVal[1]]);
  };

  useEffect(() => {
    setIntVal(val);
  }, [val]);

  useEffect(() => {
    setIntErr(error);
  }, [error]);

  const formatted = formatValue(intVal[0], intVal[1], lang);
  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      {readonly && <Typography fontSize="medium">{formatted}</Typography>}
      {!readonly && (
        <Input
          {...props}
          type="text"
          onChange={handleChanges}
          onBlur={handleChanges}
          value={formatted}
          sx={{ borderRadius: "sm", paddingLeft: 0, ...props.sx }}
          startDecorator={
            <Select
              size="sm"
              variant="plain"
              color="primary"
              value={intVal[1]}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, m: 0 }}
              onChange={(event, newValue) => {
                const currency = newValue ?? "USD";
                setIntVal([intVal[0], currency]);
                onChange([intVal[0], currency]);
              }}
            >
              <Option value="USD">{t("USD")}</Option>
              <Option value="EUR">{t("EUR")}</Option>
              <Option value="GBP">{t("GBP")}</Option>
              <Option value="JPY">{t("JPY")}</Option>
              <Option value="CNY">{t("YUAN")}</Option>
              <Option value="TWD">{t("TWD")}</Option>
              <Option value="CAD">{t("CAD")}</Option>
              <Option value="RUB">{t("RUB")}</Option>
            </Select>
          }
          endDecorator={loading && <CircularProgress size="sm" value={25} />}
        />
      )}
      {showError && intErr && <FormHelperText>{intErr}</FormHelperText>}
    </FormControl>
  );
}
