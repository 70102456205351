import { useTranslation } from "../../../../i18n";
import {
  PSIDescription,
  PSIIcon,
  PSILabel,
  PSIValue,
} from "../../../../components/inspections/PSI";
import {
  InlineDescription,
  InlineIcon,
  InlineLabel,
  InlineValue,
} from "../../../../components/inspections/Inline";
import {
  FADescription,
  FAIcon,
  FALabel,
  FAValue,
} from "../../../../components/inspections/FA";
import { useMemo } from "react";

export function useServiceTypeOptions() {
  const t = useTranslation();
  const serviceTypeOptions = useMemo(
    () => [
      {
        label: t(PSILabel),
        description: t(PSIDescription),
        value: PSIValue,
        icon: <PSIIcon />,
      },
      {
        label: t(InlineLabel),
        description: t(InlineDescription),
        value: InlineValue,
        icon: <InlineIcon />,
      },
      {
        label: t(FALabel),
        value: FAValue,
        description: t(FADescription),
        icon: <FAIcon />,
      },
    ],
    [t]
  );
  return serviceTypeOptions;
}
