import {
  Box,
  Card,
  CardContent,
  Divider,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { useInspection } from "../hooks/useInspection";
import { useTranslation } from "../../../../i18n";
import { QAgency } from "../../../../x-components/QAgency";
import {
  UpdateInspectionInput,
  useInspectionUpdateMutation,
} from "../../../../__generated__/types-and-hooks";

export default function Agency() {
  const t = useTranslation();
  const { inspection } = useInspection();
  const [inspectionUpdateMutation] = useInspectionUpdateMutation({});
  const updateFn = async (updates: Omit<UpdateInspectionInput, "id">) => {
    const res = await inspectionUpdateMutation({
      variables: {
        updates: {
          id: inspection?.id,
          ...updates,
        },
      },
    });
    return res.errors?.map((err) => err.message).join("; ");
  };

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        display: "flex",
        maxWidth: 500,
      }}
    >
      <Card variant="outlined">
        <Typography level="title-lg" /* startDecorator={<InfoOutlined />}*/>
          {t("Agency")}
        </Typography>
        <Divider />
        <CardContent
          sx={{
            gap: 2,
            p: 0,
          }}
        >
          <Sheet sx={{ maxHeight: "70vh", overflow: "auto" }}>
            <Table
              borderAxis="xBetween"
              sx={{
                p: 0,
                m: 0,
                "& tbody td:nth-child(1)": { width: 180 },
                "& tbody td": { pl: 0 },
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("Name")}</Typography>
                  </td>
                  <td>
                    <QAgency
                      agency={inspection?.agency ?? null}
                      onChange={async (agency) => {
                        await updateFn({
                          agencyId: agency?.id,
                        });
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Sheet>
        </CardContent>
      </Card>
    </Box>
  );
}
