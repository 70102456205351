import { useTranslation } from "../../../i18n";
import { Button } from "@mui/joy";
import Drawer, { DrawerProps } from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import { FiltersVM } from "./FiltersVM";
import XMultiSelect from "../../../x-components/XMultiSelect";
import { useServiceTypeOptions } from "./hooks/useServiceTypeOptions";
import { useStatusOptions } from "./hooks/useStatusOptions";
import { useVisibilityOptions } from "./hooks/useVisibilityOptions";

export interface FiltersDrawerProps {
  vm: FiltersVM;
}

export default function FiltersDrawer({
  vm,
  ...props
}: FiltersDrawerProps & DrawerProps) {
  const t = useTranslation();
  const serviceTypeOptions = useServiceTypeOptions();
  const statusOptions = useStatusOptions();
  const visibilityOptions = useVisibilityOptions();

  return (
    <Drawer {...props}>
      <Sheet
        sx={{
          borderRadius: "md",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          overflow: "auto",
          boxShadow: "0px 0px 3px #000000",
          background:
            "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
        }}
      >
        <DialogTitle>{t("Filters")}</DialogTitle>
        <ModalClose />
        <Divider sx={{ mt: "auto" }} />
        <DialogContent sx={{ gap: 2 }}>
          <XMultiSelect
            label={t("Service Type")}
            val$={vm.serviceType$}
            options={serviceTypeOptions}
          />
          <XMultiSelect
            label={t("Status")}
            val$={vm.status$}
            options={statusOptions}
          />
          <XMultiSelect
            label={t("Visibility")}
            val$={vm.visibility$}
            options={visibilityOptions}
          />
        </DialogContent>
        <Divider sx={{ mt: "auto" }} />
        <Stack
          direction="row"
          useFlexGap
          spacing={1}
          sx={{ justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={(e) => {
              vm.reset();
              props.onClose && props.onClose(e, "closeClick");
            }}
          >
            {t("Clear")}
          </Button>
          <Button
            onClick={(e) => {
              vm.reset();
              props.onClose && props.onClose(e, "closeClick");
            }}
          >
            {t("Apply")}
          </Button>
        </Stack>
      </Sheet>
    </Drawer>
  );
}
