import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputTypeMap,
  Typography,
} from "@mui/joy";
import { ReactNode, useEffect, useState, useCallback, useRef } from "react";
import { SxProps } from "@mui/joy/styles/types";

export interface QTextInputProps {
  label?: string | ReactNode;
  readonly?: boolean;
  val: string | null;
  error?: string | null;
  showError?: boolean;
  required?: boolean;
  containerSx?: SxProps;
  update: (val: string | null) => Promise<string | null | undefined>;
}

export default function QTextInput({
  val,
  readonly,
  error,
  showError,
  label,
  required,
  containerSx,
  update,
  ...props
}: InputTypeMap<{}, "div">["props"] & QTextInputProps) {
  const [intVal, setIntVal] = useState(val);
  const [intErr, setIntErr] = useState<string | null | undefined>(error);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIntVal(val);
  }, [val]);

  useEffect(() => {
    setIntErr(error);
  }, [error]);

  let timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Debounced update function
  const debouncedUpdate = useCallback(
    (newVal: string | null) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(async () => {
        setLoading(true);
        const err = await update(newVal);
        setIntErr(err);
        setLoading(false);
      }, 1000);

      return () =>
        timeoutRef.current ? clearTimeout(timeoutRef.current) : null;
    },
    [update, timeoutRef]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setIntVal(newValue);
    debouncedUpdate(newValue);
  };

  const handleBlur = async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLoading(true);
    const err = await update(intVal);
    setIntErr(err);
    setLoading(false);
  };

  return (
    <FormControl
      required={required}
      error={showError && !!error}
      sx={containerSx}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {readonly && <Typography fontSize="medium">{intVal}</Typography>}
      {!readonly && (
        <Input
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          value={intVal ?? ""}
          endDecorator={loading && <CircularProgress size="sm" value={25} />}
        />
      )}
      {showError && intErr && <FormHelperText>{intErr}</FormHelperText>}
    </FormControl>
  );
}
