import { Chip } from "@mui/joy";
import { useTranslation } from "../../../../i18n";
import { useMemo } from "react";

const statusStylesMap: Record<
  string,
  { backgroundColor: string; color: string }
> = {
  DRAFT: { backgroundColor: "#bdbdbd", color: "#000" }, // Grey
  PENDING: { backgroundColor: "#ffb74d", color: "#000" }, // Light orange
  CONFIRMED: { backgroundColor: "#0d47a1", color: "#fff" }, // Dark blue
  CANCELLED: { backgroundColor: "#d32f2f", color: "#fff" }, // Red
  INPROGRESS: { backgroundColor: "#42a5f5", color: "#000" }, // Light blue
  COMPLETED: { backgroundColor: "#388e3c", color: "#fff" }, // Green
};

function renderIcon(status: string) {
  const chipStyles = statusStylesMap[status] || {
    backgroundColor: "#e0e0e0",
    color: "#000",
  };
  return (
    <Chip size={"sm"} sx={{ ...chipStyles, borderRadius: 4 }}>
      {status.slice(0, 3)}
    </Chip>
  );
}

export function useStatusOptions() {
  const t = useTranslation();
  const statusOptions = useMemo(
    () => [
      {
        label: t("Draft"),
        value: "DRAFT",
        icon: renderIcon("DRAFT"),
      },
      {
        label: t("Pending"),
        value: "PENDING",
        icon: renderIcon("PENDING"),
      },
      {
        label: t("Confirmed"),
        value: "CONFIRMED",
        icon: renderIcon("CONFIRMED"),
      },
      {
        label: t("Cancelled"),
        value: "CANCELLED",
        icon: renderIcon("CANCELLED"),
      },
      {
        label: t("In-Progress"),
        value: "INPROGRESS",
        icon: renderIcon("INPROGRESS"),
      },
      {
        label: t("Completed"),
        value: "COMPLETED",
        icon: renderIcon("COMPLETED"),
      },
    ],
    [t]
  );
  return statusOptions;
}
