import {
  Outlet,
  Link as RouterLink,
  useMatch,
  useParams,
} from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { Breadcrumbs, Link, TabList, Tabs, Typography } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import { useTranslation } from "../../../i18n";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useInspectionDetailsByRefQuery } from "../../../__generated__/types-and-hooks";

export default function Inspection() {
  const teamCtx = useTeam();

  // Match each possible path
  const samplingMatch = useMatch("/:teamSlug/inspections/:ref/sampling");
  const supplierMatch = useMatch("/:teamSlug/inspections/:ref/supplier");
  const filesMatch = useMatch("/:teamSlug/inspections/:ref/files");
  const activityMatch = useMatch("/:teamSlug/inspections/:ref/activity");
  const agencyMatch = useMatch("/:teamSlug/inspections/:ref/agency");

  // Determine which subpath is active
  let activeTab = "details"; // Default
  if (samplingMatch) activeTab = "sampling";
  if (supplierMatch) activeTab = "supplier";
  if (filesMatch) activeTab = "files";
  if (activityMatch) activeTab = "activity";
  if (agencyMatch) activeTab = "agency";

  const { ref } = useParams();
  const t = useTranslation();
  const inspection = useInspectionDetailsByRefQuery({
    variables: { ref: ref! },
  });

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Link
          component={RouterLink}
          color="neutral"
          to={`/${teamCtx.team.slug}`}
        >
          {teamCtx.team.name}
        </Link>
        <Link
          component={RouterLink}
          color="neutral"
          to={`/${teamCtx.team.slug}/inspections`}
        >
          {t("Inspections")}
        </Link>
        <Typography level="title-sm">#{ref?.toUpperCase()}</Typography>
      </Breadcrumbs>

      <Tabs
        sx={{
          bgcolor: "transparent",
          m: 0,
          p: 0,
        }}
        value={activeTab}
      >
        <TabList
          variant="outlined"
          disableUnderline
          sx={{
            m: 2,
            mt: 0,
            mb: 0,
            gap: 0.5,
            borderRadius: "sm",
            bgcolor: "background.level2",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
              borderRadius: "sm",
              bgcolor: "neutral.900", // Darker for better contrast
              color: "neutral.100",
              fontWeight: "bold",
            },
            [`& .${tabClasses.root}:hover`]: {
              borderRadius: "sm",
              bgcolor: "neutral.800", // Lighter than selected, darker than default
              color: "neutral.50", // Slightly brighter text
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.4)", // Stronger shadow for a lift effect
              transition:
                "background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            },
          }}
        >
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={""}
            value="details"
          >
            {t("Details")}
          </Tab>
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"sampling"}
            value="sampling"
          >
            {t("Sampling")}
          </Tab>
          {/* <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"supplier"}
            value="supplier"
          >
            {t("Supplier")}
          </Tab>
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"agency"}
            value="agency"
          >
            {t("Agency")}
          </Tab> */}
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"files"}
            value="files"
          >
            {t("Files")}
          </Tab>
          <Tab
            disableIndicator
            component={RouterLink}
            relative="path"
            to={"activity"}
            value="activity"
          >
            {t("Activity")}
          </Tab>
        </TabList>
      </Tabs>

      <Outlet
        context={{
          ...teamCtx,
          inspection: inspection.data?.inspection_by_ref,
        }}
      />
    </>
  );
}
