import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import {
  useSupplierUpsertMutation,
  useTableViewSuppliersQuery,
} from "../../../__generated__/types-and-hooks";
import { AspectRatio, Button } from "@mui/joy";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TableView from "./TableView";
import { useCallback } from "react";

export default function Suppliers() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const suppliersListQuery = useTableViewSuppliersQuery({
    variables: { teamId: teamCtx.team.id, limit: 100 },
  });
  const [supplierUpsertMutation] = useSupplierUpsertMutation({});
  const handleCreateSupplier = useCallback(() => {
    supplierUpsertMutation({
      variables: {
        supplier: {
          teamId: teamCtx.team.id,
        },
      },
      onCompleted() {
        suppliersListQuery.refetch();
      },
    });
  }, [supplierUpsertMutation, teamCtx, suppliersListQuery]);

  const suppliers =
    suppliersListQuery.data && suppliersListQuery.data.supplier_list?.suppliers;

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Suppliers")}
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={handleCreateSupplier}
        >
          {t("Supplier")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {suppliersListQuery.loading && <div>Loading...</div>}
        {suppliers && suppliers.length === 0 && (
          <>
            <Typography color="neutral" level="body-md">
              {t(
                "Manage all your suppliers in one place to streamline your supply chain operations. Add suppliers manually or connect with integrations like Anvyl or NetSuite."
              )}
              <br />
              <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
                {t("Settings/Integrations")}
              </Link>
            </Typography>
            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                alt="No suppliers yet"
                src="/images/suppliers_empty_state.png"
              />
            </AspectRatio>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                startDecorator={<AddIcon />}
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={handleCreateSupplier}
              >
                {t("Add New Supplier")}
              </Button>
            </Box>
          </>
        )}
        {suppliers && suppliers.length > 0 && (
          <TableView suppliers={suppliers} />
        )}
      </Box>
    </>
  );
}
