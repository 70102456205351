import { Link as RouterLink } from "react-router-dom";
import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../i18n";
import UserApiKeys from "./UserAPIKeys";
import { Outlet, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";

export default function ApiKeys() {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Typography level="title-sm">{t("API Keys")}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("API Keys")}
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={() => navigate("./create")}
        >
          {t("Add")}
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <UserApiKeys />
      </Box>
      <Outlet />
    </>
  );
}
