import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputTypeMap,
  Sheet,
  Typography,
} from "@mui/joy";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { useLocale } from "../i18n";
import { parse, format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Backdrop, Popper } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

function parseLocaleDateString(dateString: string, locale: any) {
  console.log("dateString", dateString);
  return parse(dateString, "P", new Date(), { locale });
}

export interface DatePicker$Props {
  label?: string | ReactNode;
  val$: Subject<Date | null>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
  placeholder?: string;
  readonly?: boolean;
}

export function XDatePicker({
  val$,
  error$,
  showError,
  label,
  required,
  placeholder,
  readonly,
}: InputTypeMap<{}, "div">["props"] & DatePicker$Props) {
  const locale = useLocale();
  const val = useRxVal(val$);
  const error = useRxVal(error$);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleChanges = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const date = parseLocaleDateString(e.target.value, locale);
      val$.next(date);
    },
    [val$, locale]
  );

  const handleCalendarToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      {readonly && (
        <Typography endDecorator={<CalendarMonthIcon />}>
          {val ? format(val, "P", { locale }) : void 0}
        </Typography>
      )}
      {!readonly && (
        <>
          <Input
            sx={{ position: "relative" }}
            onChange={handleChanges}
            onBlur={handleChanges}
            value={val ? format(val, "P", { locale }) : void 0}
            placeholder={placeholder}
            // slotProps={{ input: { component: DateMaskAdapter } }}
            endDecorator={
              <IconButton
                ref={inputRef}
                variant={open ? "solid" : "plain"}
                color={open ? "primary" : "neutral"}
                onClick={handleCalendarToggle}
              >
                <CalendarMonthIcon />
              </IconButton>
            }
          />

          <Backdrop open={open} onClick={() => setOpen(false)} invisible>
            <Popper
              sx={{ zIndex: 10000 }}
              anchorEl={inputRef.current}
              open={open}
              onClick={(e) => e.stopPropagation()}
              modifiers={[
                {
                  name: "flip",
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: "viewport",
                    padding: 8,
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: "viewport",
                    padding: 8,
                  },
                },
                {
                  name: "arrow",
                  enabled: false,
                  options: {
                    element: inputRef.current,
                  },
                },
              ]}
            >
              <Sheet
                variant="outlined"
                sx={{
                  marginTop: "4px",
                  "--TableCell-height": "40px",
                  // the number is the amount of the header rows.
                  "--TableHeader-height": "calc(1 * var(--TableCell-height))",
                  "--Table-firstColumnWidth": "80px",
                  "--Table-lastColumnWidth": "144px",
                  // background needs to have transparency to show the scrolling shadows
                  "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
                  "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
                  overflow: "auto",
                  background: (
                    theme
                  ) => `linear-gradient(to right, ${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
              linear-gradient(to right, rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
              radial-gradient(
                farthest-side at 0 50%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              ),
              radial-gradient(
                  farthest-side at 100% 50%,
                  rgba(0, 0, 0, 0.12),
                  rgba(0, 0, 0, 0)
                )
                0 100%`,
                  backgroundSize:
                    "40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "local, local, scroll, scroll",
                  backgroundPosition:
                    "var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)",
                  backgroundColor: "background.surface",
                  boxShadow: "lg",
                  borderRadius: "8px",
                }}
              >
                <DateCalendar
                  onChange={(val) => {
                    val$.next(val);
                  }}
                  value={val ? dayjs(val) : void 0}
                  sx={{ margin: 0 }}
                  displayWeekNumber
                  // minDate={dayjs(+nextWeek)}
                />
              </Sheet>
            </Popper>
          </Backdrop>
        </>
      )}
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
