import Select, { SelectStaticProps } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
} from "@mui/joy";
import { ReactNode, useEffect, useRef, useState } from "react";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { SxProps } from "@mui/joy/styles/types";

export interface QSelectProps {
  readonly?: boolean;
  label?: string | ReactNode;
  placeholder?: string;
  val: string | null;
  error?: string | null;
  showError?: boolean;
  required?: boolean;
  options: { label: string | ReactNode; value: string; icon?: JSX.Element }[];
  containerSx?: SxProps;
  nullable?: boolean;
  update: (val: string | null) => Promise<string | null | undefined>;
}

export default function QSelect({
  required,
  label,
  placeholder,
  showError,
  val,
  error,
  options,
  containerSx,
  readonly,
  nullable,
  update,
}: QSelectProps) {
  const action: SelectStaticProps["action"] = useRef(null);
  const [intVal, setIntVal] = useState(val);
  const [intErr, setIntErr] = useState<string | null | undefined>(error);
  const [loading, setLoading] = useState(false);
  const onChange = async (newVal: string | null) => {
    setLoading(true);
    const err = await update(newVal);
    setIntErr(err);
    setLoading(false);
  };

  useEffect(() => {
    setIntVal(val);
  }, [val]);

  useEffect(() => {
    setIntErr(error);
  }, [error]);

  return (
    <FormControl
      required={required}
      error={showError && !!error}
      sx={containerSx}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        disabled={readonly}
        action={action}
        value={intVal}
        placeholder={placeholder}
        onChange={(_, value) => {
          setIntVal(value);
          onChange(value);
        }}
        sx={
          readonly
            ? {
                border: 0,
                padding: 0,
              }
            : {}
        }
        {...(intVal && {
          // display the button and remove select indicator
          // when user has selected a value
          endDecorator: loading ? (
            <CircularProgress size="sm" value={25} />
          ) : (
            !readonly &&
            nullable && (
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onMouseDown={(event) => {
                  // don't open the popup when clicking on this button
                  event.stopPropagation();
                }}
                onClick={() => {
                  onChange(null);
                  action.current?.focusVisible();
                }}
              >
                <CloseRounded />
              </IconButton>
            )
          ),
          indicator: null,
        })}
        renderValue={(selectedOption) => (
          <Box sx={{ display: "flex", gap: "0.25rem" }}>
            {selectedOption && (
              <Chip
                variant="soft"
                color="primary"
                sx={{ borderRadius: 4 }}
                startDecorator={
                  options.find((o) => o.value === selectedOption.value)?.icon
                }
              >
                {selectedOption.label}
              </Chip>
            )}
          </Box>
        )}
        slotProps={{
          listbox: {
            sx: {
              width: "100%",
            },
          },
        }}
      >
        {options.map(({ label, value, icon }, index) => (
          <Option value={value}>
            {icon} {label}
          </Option>
        ))}
      </Select>
      {showError && intErr && <FormHelperText>{intErr}</FormHelperText>}
    </FormControl>
  );
}
