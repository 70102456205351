import { Box, Card, CardContent, Divider, Typography } from "@mui/joy";
import { useTranslation } from "../../../../i18n";
import { BehaviorSubject } from "rxjs";
import StepSampling from "../draft/StepSampling";

export default function Sampling() {
  // const inspection = useInspection();
  const t = useTranslation();

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        display: "flex",
      }}
    >
      <Card variant="outlined">
        <Typography level="title-lg">{t("Sampling")}</Typography>
        <Divider />
        <CardContent
          sx={{
            gap: 2,
            p: 0,
            m: 0,
            overflow: "auto",
          }}
        >
          <StepSampling vm={{ samplings$: new BehaviorSubject({}) } as any} />
        </CardContent>
      </Card>
    </Box>
  );
}
