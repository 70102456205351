import CreateDraft from "./CreateDraft";
import Inspections from "./Inspections";
import Inspection from "./Inspection";
import TeamDash from "./TeamDash";
import Details from "./inspection/Details";
import Sampling from "./inspection/Sampling";
import Supplier from "./inspection/Supplier";
import Files from "./inspection/Files";
import Activity from "./inspection/Activity";
import Agency from "./inspection/Agency";

export const inspectionRoutes = [
  {
    path: "/:teamSlug",
    element: <TeamDash />,
    children: [],
  },
  {
    path: "/:teamSlug/inspections",
    element: <Inspections />,
    children: [],
  },
  {
    path: "/:teamSlug/inspections/:ref",
    element: <Inspection />,
    children: [
      {
        path: "/:teamSlug/inspections/:ref",
        element: <Details />,
        children: [],
      },
      {
        path: "/:teamSlug/inspections/:ref/sampling",
        element: <Sampling />,
        children: [],
      },
      {
        path: "/:teamSlug/inspections/:ref/supplier",
        element: <Supplier />,
        children: [],
      },
      {
        path: "/:teamSlug/inspections/:ref/agency",
        element: <Agency />,
        children: [],
      },
      {
        path: "/:teamSlug/inspections/:ref/files",
        element: <Files />,
        children: [],
      },
      {
        path: "/:teamSlug/inspections/:ref/activity",
        element: <Activity />,
        children: [],
      },
    ],
  },
  {
    path: "/:teamSlug/inspections/new",
    element: <CreateDraft />,
    children: [],
  },
];
