import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { Link as RouterLink } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Breadcrumbs, Button, Input, Link } from "@mui/joy";
import React, { useCallback, useMemo } from "react";
import {
  useInspectionCreateDraftMutation,
  useInspectionListQuery,
} from "../../../__generated__/types-and-hooks";
import AddIcon from "@mui/icons-material/Add";
import TableView from "./views/TableView";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/TuneRounded";
import FiltersDrawer from "./FiltersDrawer";
import { FiltersVM } from "./FiltersVM";

export default function Inspections() {
  const vm = useMemo(() => new FiltersVM(), []);
  const [open, setOpen] = React.useState(false);
  const t = useTranslation();
  const teamCtx = useTeam();
  const inspectionsListQuery = useInspectionListQuery({
    variables: { teamId: teamCtx.team.id, teamKind: teamCtx.team.kind },
  });
  const [inspectionCreateDraftMutation, { loading }] =
    useInspectionCreateDraftMutation();

  const inspections =
    inspectionsListQuery.data &&
    inspectionsListQuery.data.inspection_list?.inspections;

  const handleAddInspection = useCallback(() => {
    inspectionCreateDraftMutation({
      variables: {
        draft: {
          brandId: teamCtx.team.id,
        },
      },
      onCompleted() {
        inspectionsListQuery.refetch();
      },
    });
  }, [teamCtx, inspectionCreateDraftMutation, inspectionsListQuery]);

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Link
          component={RouterLink}
          color="neutral"
          to={`/${teamCtx.team.slug}`}
        >
          {teamCtx.team.name}
        </Link>
        <Typography level="title-sm">{t("Inspections")}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: "flex", m: 2 }} gap={1}>
        <Input
          type="text"
          placeholder={t("Ref #")}
          startDecorator={<SearchIcon />}
        />

        <Box sx={{ flex: 1 }}></Box>
        <Button
          variant="outlined"
          color="neutral"
          startDecorator={<TuneIcon />}
          onClick={() => setOpen(true)}
        >
          {t("Filters")}
        </Button>
        <Button
          loading={loading}
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={handleAddInspection}
        >
          {t("Add")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          // minHeight: "calc(100vh - 150px)",
        }}
      >
        {inspections && inspections.length === 0 && (
          <>
            <Typography color="neutral" level="body-md">
              {t(
                "No Inspections yet! Start by adding one manually or connecting to an integration like Anvyl or NetSuite."
              )}
            </Typography>

            <Link
              component={RouterLink}
              to={`/${teamCtx.team.slug}/settings/integrations`}
            >
              {t("Settings/Integrations")}
            </Link>

            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                alt={t("No Inspections yet")}
                src="/images/inspections_empty_state.png"
              />
            </AspectRatio>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button sx={{ mb: 2 }} variant="outlined">
                {t("Add")}
              </Button>
            </Box>
          </>
        )}

        {inspections && inspections.length > 0 && (
          <>
            <TableView inspections={inspections} />
          </>
        )}
      </Box>

      <FiltersDrawer
        vm={vm}
        anchor="right"
        size="md"
        variant="plain"
        open={open}
        onClose={(e) => {
          console.log("event", e);
          setOpen(false);
        }}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      />
    </>
  );
}
