import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { Box, Divider } from "@mui/joy";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link, useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/joy/Tooltip";
import { useTranslation } from "../i18n";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import PublicIcon from "@mui/icons-material/Public";
import React, { ComponentType } from "react";
import { useTeamInfoQuery } from "../__generated__/types-and-hooks";
import TeamSelect from "./TeamSelect";
import ProfileDropDown from "./header/ProfileDropDown";
import LanguageToggle from "./header/LanguageToggle";

export interface NavigationProps {
  teamKind: "AGENCY" | "BRAND";
}

export interface NavItemProps {
  selected: boolean;
  to: string;
  title: string;
  icon: ComponentType<{ fontSize?: any; sx?: object }>;
  iconSelected?: ComponentType<{ fontSize?: any; sx?: object }>;
}

export function NavItem({
  title,
  selected,
  to,
  icon,
  iconSelected,
}: NavItemProps) {
  const Icon = selected ? iconSelected ?? icon : icon;

  return (
    <ListItem sx={{ ml: 1, mr: 1 }}>
      <ListItemButton component={Link} selected={selected} to={to}>
        <ListItemDecorator>
          <Tooltip
            sx={{ display: { xs: "none", sm: "block", lg: "none" } }}
            title={title}
            placement="right"
            size="md"
            variant="solid"
          >
            <Icon fontSize="small" />
          </Tooltip>
          <Icon
            fontSize="small"
            sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
          />
        </ListItemDecorator>
        <ListItemContent
          sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
        >
          {title}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export default function Navigation({ teamKind }: NavigationProps) {
  const t = useTranslation();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);
  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });
  const team = teamInfoQuery.data?.team_by_slug;

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: 0,
        overflow: "hidden auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        [`& .${listItemButtonClasses.root}`]: {
          gap: 1.5,
        },
      }}
    >
      <List
        size="sm"
        sx={{
          gap: 1,
          "--List-nestedInsetStart": { sm: "0px", lg: "30px" },
          "--ListItem-radius": (theme) => theme.vars.radius.sm,
        }}
      >
        <ListItem>
          <TeamSelect />
        </ListItem>
        {team && (
          <>
            {team.kind === "AGENCY" && (
              <NavItem
                title={t("Inquiries")}
                selected={section === "inquiries"}
                to={`/${teamSlug}/inquiries`}
                icon={(props) => <PublicOutlinedIcon {...props} />}
                iconSelected={(props) => <PublicIcon {...props} />}
              />
            )}
            <NavItem
              title={t("Inspections")}
              selected={section === "inspections"}
              to={`/${teamSlug}/inspections`}
              icon={(props) => <FactCheckOutlinedIcon {...props} />}
              iconSelected={(props) => <FactCheckIcon {...props} />}
            />
            {team.kind === "BRAND" && (
              <NavItem
                title={t("SKU")}
                selected={section === "sku"}
                to={`/${teamSlug}/sku`}
                icon={(props) => <CategoryOutlinedIcon {...props} />}
                iconSelected={(props) => <CategoryIcon {...props} />}
              />
            )}
            {team.kind === "BRAND" && (
              <NavItem
                title={t("PO")}
                selected={section === "purchase-orders"}
                to={`/${teamSlug}/purchase-orders`}
                icon={(props) => <ReceiptOutlinedIcon {...props} />}
                iconSelected={(props) => <ReceiptIcon {...props} />}
              />
            )}
            {team.kind === "BRAND" && (
              <NavItem
                title={t("Suppliers")}
                selected={section === "suppliers"}
                to={`/${teamSlug}/suppliers`}
                icon={(props) => <FactoryOutlinedIcon {...props} />}
                iconSelected={(props) => <FactoryIcon {...props} />}
              />
            )}
            {team.kind === "BRAND" && (
              <NavItem
                title={t("Contacts")}
                selected={section === "contacts"}
                to={`/${teamSlug}/contacts`}
                icon={(props) => <ContactPageOutlinedIcon {...props} />}
                iconSelected={(props) => <ContactPageIcon {...props} />}
              />
            )}
          </>
        )}
        <Divider />
        <NavItem
          title={t("Settings")}
          selected={section === "settings"}
          to={`/${teamSlug}/settings`}
          icon={(props) => <SettingsOutlinedIcon {...props} />}
          iconSelected={(props) => <SettingsIcon {...props} />}
        />
      </List>
      {/* <Card
        invertedColors
        variant="soft"
        color="warning"
        size="sm"
        sx={{ boxShadow: "none" }}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography level="title-sm">Used space</Typography>
        </Stack>
        <Typography level="body-xs">
          Your team has used 80% of your available space. Need more?
        </Typography>
      </Card> */}
      <List
        size="sm"
        sx={{
          flexGrow: 0,
          m: 1,
        }}
      >
        <LanguageToggle />
      </List>
      <Divider />
      <List
        size="sm"
        sx={{
          flexGrow: 0,
          mt: 1,
          mb: 1,
        }}
      >
        <ListItem>
          <ProfileDropDown />
        </ListItem>
      </List>
    </Box>
  );
}
