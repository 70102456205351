import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { Link as RouterLink } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { Breadcrumbs, Card, CardContent, Link } from "@mui/joy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";

export default function TeamDash() {
  const t = useTranslation();
  const teamCtx = useTeam();

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumbs"
        sx={{
          "--Breadcrumbs-gap": "4px",
          m: 1,
        }}
        size="sm"
      >
        <Link component={RouterLink} color="neutral" to="/">
          <DashboardIcon />
        </Link>
        <Typography level="title-sm">{teamCtx.team.name}</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          p: 2,
          width: "100%",
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
        }}
        gap={2}
      >
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            background:
              "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
              background:
                "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(7 2 33) 0%, rgb(13 41 51), rgb(2 6 33) 50%, rgb(11 17 43) 25% 75%, rgb(5 2 33) 100%)",
            },
          }}
        >
          <FactCheckOutlinedIcon fontSize="large" />
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {t("Inspections")}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              sx={{ mb: 1 }}
            >
              <Link
                component={RouterLink}
                color="neutral"
                to="./inspections"
                overlay
                underline="none"
                sx={{ color: "text.tertiary" }}
              >
                {t("Manage inspections")}
              </Link>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            background:
              "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
              background:
                "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(7 2 33) 0%, rgb(13 41 51), rgb(2 6 33) 50%, rgb(11 17 43) 25% 75%, rgb(5 2 33) 100%)",
            },
          }}
        >
          <CategoryOutlinedIcon fontSize="large" />
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {t("SKUs")}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              sx={{ mb: 1 }}
            >
              <Link
                component={RouterLink}
                color="neutral"
                to="./sku"
                overlay
                underline="none"
                sx={{ color: "text.tertiary" }}
              >
                {t("Manage SKUs")}
              </Link>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            background:
              "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
              background:
                "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(7 2 33) 0%, rgb(13 41 51), rgb(2 6 33) 50%, rgb(11 17 43) 25% 75%, rgb(5 2 33) 100%)",
            },
          }}
        >
          <ReceiptOutlinedIcon fontSize="large" />
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {t("Purchase Orders")}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              sx={{ mb: 1 }}
            >
              <Link
                component={RouterLink}
                color="neutral"
                to="./purchase-orders"
                overlay
                underline="none"
                sx={{ color: "text.tertiary" }}
              >
                {t("Manage POs")}
              </Link>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            background:
              "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
              background:
                "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(7 2 33) 0%, rgb(13 41 51), rgb(2 6 33) 50%, rgb(11 17 43) 25% 75%, rgb(5 2 33) 100%)",
            },
          }}
        >
          <FactoryOutlinedIcon fontSize="large" />
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {t("Suppliers")}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              sx={{ mb: 1 }}
            >
              <Link
                component={RouterLink}
                color="neutral"
                to="./suppliers"
                overlay
                underline="none"
                sx={{ color: "text.tertiary" }}
              >
                {t("Manage Suppliers")}
              </Link>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            background:
              "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
              background:
                "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(7 2 33) 0%, rgb(13 41 51), rgb(2 6 33) 50%, rgb(11 17 43) 25% 75%, rgb(5 2 33) 100%)",
            },
          }}
        >
          <ContactPageOutlinedIcon fontSize="large" />
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {t("Contacts")}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              sx={{ mb: 1 }}
            >
              <Link
                component={RouterLink}
                color="neutral"
                to="./contacts"
                overlay
                underline="none"
                sx={{ color: "text.tertiary" }}
              >
                {t("Manage Contacts")}
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
