import {
  Box,
  Card,
  CardContent,
  Divider,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { useInspection } from "../hooks/useInspection";
import { useTranslation } from "../../../../i18n";
import { useServiceTypeOptions } from "../hooks/useServiceTypeOptions";
import { useStatusOptions } from "../hooks/useStatusOptions";
import { useVisibilityOptions } from "../hooks/useVisibilityOptions";
import QSelect from "../../../../x-components/QSelect";
import QDatePicker from "../../../../x-components/QDatePicker";
import {
  InspectionStatus,
  InspectionVisibility,
  UpdateInspectionInput,
  useInspectionUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import { QMoneyInput } from "../../../../x-components/QMoneyInput";
import QTextInput from "../../../../x-components/QTextInput";
import QNumberInput from "../../../../x-components/QNumberInput";
import SupplierRef from "../../../../components/SupplierRef";
import { useTeam } from "../../TeamLayout";
import CountryFlag from "../../../../components/CountryFlag";
import QSupplier from "../../../../x-components/QSupplier";
import { QAgency } from "../../../../x-components/QAgency";

export default function Details() {
  const editable = new Set([
    "serviceType",
    "status",
    "visibility",
    // "requestedServiceDateStart",
    "requestedServiceDateEnd",
  ]);
  const { inspection } = useInspection();
  const [inspectionUpdateMutation] = useInspectionUpdateMutation({});
  const teamCtx = useTeam();
  const t = useTranslation();
  const serviceTypeOptions = useServiceTypeOptions();
  const statusOptions = useStatusOptions();
  const status = statusOptions.find((opt) => opt.value === inspection?.status);
  const visibilityOptions = useVisibilityOptions();
  const visibility = visibilityOptions.find(
    (opt) => opt.value === inspection?.visibility
  );

  const updateFn = async (updates: Omit<UpdateInspectionInput, "id">) => {
    const res = await inspectionUpdateMutation({
      variables: {
        updates: {
          id: inspection?.id,
          ...updates,
        },
      },
    });
    return res.errors?.map((err) => err.message).join("; ");
  };

  const supplier = inspection?.supplier;

  return (
    <Box
      sx={{
        p: 2,
        columns: "400px",
      }}
    >
      {inspection && (
        <Card sx={{ mb: 2, breakInside: "avoid" }} variant="outlined">
          <Typography
            level="title-lg"
            startDecorator={
              <>
                {visibility?.icon}
                {status?.icon}
              </>
            }
          >
            {t("Inspection #{ref}", { ref: inspection?.ref.toUpperCase() })}
          </Typography>
          <Divider />
          <CardContent
            sx={{
              gap: 2,
              p: 0,
              m: 0,
            }}
          >
            <Sheet sx={{ overflow: "auto" }}>
              <Table
                borderAxis="xBetween"
                sx={{
                  p: 0,
                  m: 0,
                  "& tbody td:nth-child(1)": { width: 180 },
                  "& tbody td": { pl: 0 },
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Service Type")}
                      </Typography>
                    </td>
                    <td>
                      <QSelect
                        readonly={!editable.has("serviceType")}
                        val={inspection.serviceType ?? null}
                        options={serviceTypeOptions}
                        update={(serviceType) => updateFn({ serviceType })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Status")}</Typography>
                    </td>
                    <td>
                      <QSelect
                        readonly={!editable.has("status")}
                        val={inspection.status}
                        options={statusOptions}
                        update={(status) =>
                          updateFn({ status: status as InspectionStatus })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Visibility")}</Typography>
                    </td>
                    <td>
                      <QSelect
                        readonly={!editable.has("visibility")}
                        val={inspection.visibility ?? null}
                        options={visibilityOptions}
                        update={(visibility) =>
                          updateFn({
                            visibility: visibility as InspectionVisibility,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Requested Service Date (Start)")}
                      </Typography>
                    </td>
                    <td>
                      <QDatePicker
                        readonly={!editable.has("requestedServiceDateStart")}
                        val={inspection.serviceDate}
                        update={(requestedServiceDateStart) =>
                          updateFn({
                            serviceDate: requestedServiceDateStart,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Requested Service Date (End)")}
                      </Typography>
                    </td>
                    <td>
                      <QDatePicker
                        readonly={!editable.has("requestedServiceDateEnd")}
                        val={inspection.serviceDate}
                        update={(requestedServiceDateEnd) =>
                          updateFn({
                            serviceDate: requestedServiceDateEnd,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Actual Service Date (Start)")}
                      </Typography>
                    </td>
                    <td>
                      <QDatePicker
                        readonly={!editable.has("actualServiceDateStart")}
                        val={inspection.serviceDate}
                        update={(actualServiceDateEnd) =>
                          updateFn({
                            serviceDate: actualServiceDateEnd,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Actual Service Date (End)")}
                      </Typography>
                    </td>
                    <td>
                      <QDatePicker
                        readonly={!editable.has("actualServiceDateEnd")}
                        val={inspection.serviceDate}
                        update={(actualServiceDateEnd) =>
                          updateFn({
                            serviceDate: actualServiceDateEnd,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Reason For Inspection Date Change")}
                      </Typography>
                    </td>
                    <td>
                      <QTextInput
                        readonly={!editable.has("reason")}
                        val={"Test"}
                        update={(reason) =>
                          updateFn({
                            serviceDate: new Date(),
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Estimated Service Days")}
                      </Typography>
                    </td>
                    <td>
                      <QNumberInput
                        // readonly={!editable.has("estimatedServiceDays")}
                        val={10}
                        update={(num) =>
                          updateFn({
                            serviceDate: new Date(),
                          })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Estimated Costs")}
                      </Typography>
                    </td>
                    <td>
                      <QMoneyInput
                        readonly={!editable.has("estimatedCosts")}
                        val={[10, "USD"]}
                        update={(data) =>
                          updateFn({
                            serviceDate: new Date(),
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Actual Costs")}
                      </Typography>
                    </td>
                    <td>
                      <QMoneyInput
                        readonly={!editable.has("actualCosts")}
                        val={[10, "USD"]}
                        update={(data) =>
                          updateFn({
                            serviceDate: new Date(),
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Sheet>
          </CardContent>
        </Card>
      )}
      {inspection && (
        <Card sx={{ mb: 2, breakInside: "avoid" }} variant="outlined">
          <Typography
            sx={{ width: "100%" }}
            level="title-lg"
            endDecorator={
              supplier ? (
                <SupplierRef
                  copy
                  openInNewWindow
                  teamSlug={teamCtx.team.slug}
                  supplierRef={supplier.uniqueId}
                />
              ) : null
            }
          >
            {t("Supplier")}
          </Typography>
          <Divider />
          <CardContent
            sx={{
              gap: 2,
              p: 0,
              m: 0,
            }}
          >
            <Sheet sx={{ maxHeight: "70vh", overflow: "auto" }}>
              <Table
                borderAxis="xBetween"
                sx={{
                  p: 0,
                  m: 0,
                  "& tbody td:nth-child(1)": { width: 180 },
                  "& tbody td": { pl: 0 },
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Name")}</Typography>
                    </td>
                    <td>
                      <QSupplier
                        teamId={teamCtx.team.id}
                        supplier={inspection?.supplier ?? null}
                        update={(s) => updateFn({ supplierId: s?.id ?? null })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Address Line 1")}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{supplier?.addressLine1}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Address Line 2")}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{supplier?.addressLine2}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("City")}</Typography>
                    </td>
                    <td>
                      <Typography>{supplier?.city}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">
                        {t("Postal Code")}
                      </Typography>
                    </td>
                    <td>
                      <Typography>{supplier?.postalCode}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Country")}</Typography>
                    </td>
                    <td>
                      {supplier?.countryCode && (
                        <CountryFlag code={supplier.countryCode} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Contacts")}</Typography>
                    </td>
                    <td>
                      {supplier?.contacts?.map((contact) => (
                        <Table
                          key={contact.id}
                          borderAxis="xBetween"
                          sx={{
                            p: 0,
                            m: 0,
                            "& tbody td:nth-child(1)": { width: 40 },
                            "& tbody td": { pl: 0 },
                          }}
                        >
                          <tbody>
                            <tr>
                              <td>{t("Name")}</td>
                              <td>{contact.name}</td>
                            </tr>
                            {contact.contactMethods?.map((method, i) => (
                              <tr key={i}>
                                <td>{method.key}</td>
                                <td>{method.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Sheet>
          </CardContent>
        </Card>
      )}
      {inspection && (
        <Card sx={{ mb: 2, breakInside: "avoid" }} variant="outlined">
          <Typography level="title-lg" /* startDecorator={<InfoOutlined />}*/>
            {t("Agency")}
          </Typography>
          <Divider />
          <CardContent
            sx={{
              gap: 2,
              p: 0,
            }}
          >
            <Sheet sx={{ maxHeight: "70vh", overflow: "auto" }}>
              <Table
                borderAxis="xBetween"
                sx={{
                  p: 0,
                  m: 0,
                  "& tbody td:nth-child(1)": { width: 180 },
                  "& tbody td": { pl: 0 },
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <Typography level="body-sm">{t("Name")}</Typography>
                    </td>
                    <td>
                      <QAgency
                        agency={inspection?.agency ?? null}
                        onChange={async (agency) => {
                          await updateFn({
                            agencyId: agency?.id,
                          });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Sheet>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
