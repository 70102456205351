import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import AspectRatio from "@mui/joy/AspectRatio";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import { Country, useCountryListQuery } from "../__generated__/types-and-hooks";
import { LinearProgress, InputTypeMap } from "@mui/joy";
import { Observable, Subject } from "rxjs";
import { ReactNode, useCallback } from "react";
import { useRxVal } from "../hooks/useRx";

export interface XCountrySelect$Props {
  label?: string | ReactNode;
  val$: Subject<string>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export default function XCountrySelect({
  val$,
  error$,
  showError,
  label,
  required,
}: InputTypeMap<{}, "div">["props"] & XCountrySelect$Props) {
  const countryList = useCountryListQuery();
  const val = useRxVal(val$);
  const error = useRxVal(error$);

  const handleChanges = useCallback(
    (e: any, val: Country | null) => {
      if (val) {
        val$.next(val.code);
      }
    },
    [val$]
  );

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      {countryList.loading && <LinearProgress size="sm" />}
      {countryList.data && Array.isArray(countryList.data.country_list) && (
        <Autocomplete
          size="sm"
          autoHighlight
          onChange={handleChanges}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          startDecorator={
            <AspectRatio ratio="1" sx={{ minWidth: 20, borderRadius: "50%" }}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${val.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${val.toLowerCase()}.png`}
                alt=""
              />
            </AspectRatio>
          }
          value={countryList.data.country_list.find((c) => c.code === val)}
          options={countryList.data.country_list}
          renderOption={(optionProps, option) => (
            <AutocompleteOption {...optionProps}>
              <ListItemDecorator>
                <AspectRatio
                  ratio="1"
                  sx={{ minWidth: 20, borderRadius: "50%" }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                </AspectRatio>
              </ListItemDecorator>
              {option.label}
              <Typography component="span" textColor="text.tertiary" ml={0.5}>
                (+{option.phone})
              </Typography>
            </AutocompleteOption>
          )}
          slotProps={{
            input: {
              autoComplete: "new-password", // disable autocomplete and autofill
            },
          }}
        />
      )}
    </FormControl>
  );
}
