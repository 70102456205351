import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Box, Chip, FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { ReactNode } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";

export interface XMultiSelectProps {
  label?: string | ReactNode;
  placeholder?: string;
  val$: Subject<string[]>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
  options: { label: string | ReactNode; value: string; icon?: JSX.Element }[];
}

export default function XMultiSelect({
  required,
  label,
  placeholder,
  showError,
  val$,
  error$,
  options,
}: XMultiSelectProps) {
  const val = useRxVal(val$);
  const error = useRxVal(error$);

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: string[] | null
  ) => {
    val$.next(newValue ?? []);
  };

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}

      <Select
        multiple
        value={val}
        placeholder={placeholder}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", gap: "0.25rem" }}>
            {selected.map((selectedOption) => (
              <Chip variant="soft" color="primary" sx={{ borderRadius: 4 }}>
                {options.find((o) => o.value === selectedOption.value)?.icon ??
                  selectedOption.label}
              </Chip>
            ))}
          </Box>
        )}
        slotProps={{
          listbox: {
            sx: {
              width: "100%",
            },
          },
        }}
      >
        {options.map(({ label, value, icon }, index) => (
          <Option value={value}>
            {icon} {label}
          </Option>
        ))}
      </Select>
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
