import Layout from "../../components/Layout";
import Navigation from "../../components/Navigation";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useTeamInfoQuery } from "../../__generated__/types-and-hooks";
import { Button, LinearProgress, Stack } from "@mui/joy";
import PageNotFound from "../PageNotFound";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import MemoryIcon from "@mui/icons-material/MemoryRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useTranslation } from "../../i18n";

export interface TeamContext {
  team: Exclude<
    Exclude<
      ReturnType<typeof useTeamInfoQuery>["data"],
      undefined
    >["team_by_slug"],
    undefined | null
  >;
}

export function useTeam() {
  return useOutletContext<TeamContext>();
}

export default function TeamLayout() {
  const t = useTranslation();
  const { teamSlug } = useParams();
  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);
  const team = teamInfoQuery.data?.team_by_slug;
  if (!teamInfoQuery.loading && !team) {
    return <PageNotFound />;
  }

  return (
    <>
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: "flex", sm: "none" },
          zIndex: "999",
          bottom: 0,
          position: "fixed",
          width: "100dvw",
          p: 1,
          m: 0,
          // backgroundColor: "background.body",
          backdropFilter: "blur(10px)",
          borderTop: "1px solid",
          borderColor: "divider",
          boxShadow: "1px 0px 2px #000000",
          background:
            "radial-gradient(circle at 50% 50%, rgb(0 0 0) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(5 2 33) 0%, rgb(11 17 43) 25%, rgb(2 6 33) 50%, rgb(13 41 51) 75%, rgb(7 2 33) 100%)",
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
        }}
      >
        <Button
          variant={"plain"}
          color="neutral"
          component={Link}
          to={"/"}
          size="sm"
          startDecorator={<DashboardIcon fontSize="small" />}
          sx={{
            fontSize: 10,
            width: "100%",
            flexDirection: "column",
            "--Button-gap": 0,
          }}
        >
          {t("Dashboard")}
        </Button>
        {team?.kind === "AGENCY" && (
          <Button
            variant={!section || section === "inquiries" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/inquiries`}
            size="sm"
            startDecorator={<PublicOutlinedIcon fontSize="small" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("Inquiries")}
          </Button>
        )}

        <Button
          variant={!section || section === "inspections" ? "solid" : "plain"}
          color="neutral"
          component={Link}
          to={`/${teamSlug}/inspections`}
          size="sm"
          startDecorator={<FactCheckOutlinedIcon fontSize="small" />}
          sx={{
            fontSize: 10,
            width: "100%",
            flexDirection: "column",
            "--Button-gap": 0,
          }}
        >
          {t("Inspections")}
        </Button>

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "sku" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/sku`}
            size="sm"
            startDecorator={<MemoryIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("SKUs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "purchase-orders" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/purchase-orders`}
            size="sm"
            startDecorator={<ReceiptLongIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("POs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "suppliers" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/suppliers`}
            size="sm"
            startDecorator={<FactoryOutlinedIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("Suppliers")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "contacts" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/contacts`}
            size="sm"
            startDecorator={<ContactPageOutlinedIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("Contacts")}
          </Button>
        )}
      </Stack>

      <Layout.Root>
        <Layout.SideNav>
          {team && <Navigation teamKind={team.kind as "BRAND" | "AGENCY"} />}
        </Layout.SideNav>
        <Layout.Main sx={{ overflow: "auto" }}>
          {teamInfoQuery.loading && <LinearProgress />}
          {teamInfoQuery.data?.team_by_slug && (
            <Outlet
              context={
                {
                  team: teamInfoQuery.data?.team_by_slug,
                } satisfies TeamContext & any
              }
            />
          )}
        </Layout.Main>
      </Layout.Root>
    </>
  );
}
