import Layout from "../../components/Layout";
import { Outlet } from "react-router-dom";
import Navigation from "./Navigation";

export default function DashboardLayout() {
  return (
    <Layout.Root>
      <Layout.SideNav>
        <Navigation />
      </Layout.SideNav>
      <Layout.Main>
        <Outlet />
      </Layout.Main>
    </Layout.Root>
  );
}
