import MenuButton from "@mui/joy/MenuButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dropdown from "@mui/joy/Dropdown";
import {
  Avatar,
  ListDivider,
  ListItemDecorator,
  Stack,
  Typography,
  Box,
  useColorScheme,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useUserMeQuery } from "../__generated__/types-and-hooks";
import VerifiedIcon from "@mui/icons-material/Verified";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { useTranslation } from "../i18n";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddIcon from "@mui/icons-material/Add";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

export interface TeamMiniCardProps {
  name: string;
  slug: string;
  picture?: string | null;
}

export function TeamMiniCard({ name, slug, picture }: TeamMiniCardProps) {
  const { mode } = useColorScheme();

  return (
    <>
      <Box
        sx={{
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            m: "-2px",
            borderRadius: 4,
            background:
              "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
          },
        }}
      >
        <Avatar
          size="sm"
          sx={{
            height: 32,
            width: "100%",
            minWidth: 32,
            borderRadius: 4,
          }}
          src={
            picture
              ? process.env.REACT_APP_API_URL + "/" + picture
              : mode === "light"
              ? "/images/qcsku_light.jpg"
              : "/images/qcsku_dark.jpg"
          }
        />
      </Box>
      <Typography
        noWrap
        sx={{
          display: { xs: "flex", sm: "none", lg: "flex" },
          textAlign: "left",
          ml: 1,
          fontWeight: "lg",
          flex: 1,
        }}
        endDecorator={<ArrowDropDownIcon fontSize="medium" />}
      >
        {name}
      </Typography>
    </>
  );
}

export default function TeamSelect() {
  const user = useUserMeQuery();
  const navigate = useNavigate();
  const { teamSlug } = useParams();
  const t = useTranslation();
  const { mode } = useColorScheme();

  let team = null;
  if (user.data && teamSlug) {
    const edge = user.data.user_me.teamEdges.find(
      ({ team }) => team.slug === teamSlug
    );
    if (edge) {
      team = edge.team;
    }
  }

  return (
    <Dropdown>
      <MenuButton
        fullWidth
        sx={{
          p: 0,
          m: 0,
          maxHeight: "32px",
        }}
        color={team ? "neutral" : "primary"}
        startDecorator={
          team ? null : <ArrowForwardIcon sx={{ ml: 1 }} fontSize="medium" />
        }
      >
        {team ? (
          <TeamMiniCard
            key={team.id}
            name={team.name}
            slug={team.slug}
            picture={team.picture}
          />
        ) : (
          <Typography
            sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
            endDecorator={<ArrowDropDownIcon fontSize="medium" />}
          >
            {t("Switch Account")}
          </Typography>
        )}
      </MenuButton>
      <Menu>
        <ListDivider>{t("Switch Account")}</ListDivider>
        {user.loading && <MenuItem>Loading...</MenuItem>}
        {user.data &&
          user.data.user_me.teamEdges.map(({ team }) => (
            <MenuItem key={team.id} onClick={() => navigate(`/${team.slug}`)}>
              <ListItemDecorator sx={{ color: "inherit" }}>
                <Box
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      m: "-2px",
                      borderRadius: "50%",
                      background:
                        "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
                    },
                  }}
                >
                  <Avatar
                    size="sm"
                    sx={{
                      height: 23,
                      width: 23,
                    }}
                    src={
                      team.picture
                        ? process.env.REACT_APP_API_URL + "/" + team.picture
                        : mode === "light"
                        ? "/images/qcsku_light.jpg"
                        : "/images/qcsku_dark.jpg"
                    }
                  />
                </Box>
              </ListItemDecorator>
              <Stack direction="column">
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: 120,
                  }}
                >
                  {team.name}{" "}
                  {!team ? (
                    <Typography color="primary" fontSize={12}>
                      <VerifiedIcon />
                    </Typography>
                  ) : (
                    ""
                  )}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        <ListDivider>{t("Explore")}</ListDivider>
        <MenuItem onClick={() => navigate("/network")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <TravelExploreIcon />
          </ListItemDecorator>{" "}
          {t("Network")}
        </MenuItem>
        <MenuItem onClick={() => navigate("/join")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <GroupOutlinedIcon />
          </ListItemDecorator>{" "}
          {t("Join")}
        </MenuItem>
        <MenuItem onClick={() => navigate("/create-brand")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <AddIcon />
          </ListItemDecorator>{" "}
          {t("Brand")}
        </MenuItem>
        <MenuItem onClick={() => navigate("/create-agency")}>
          <ListItemDecorator sx={{ color: "inherit" }}>
            <AddIcon />
          </ListItemDecorator>{" "}
          {t("Agency")}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
