import { useColorScheme } from "@mui/joy/styles";
import Box from "@mui/joy/Box";
import LanguageToggle from "../../components/header/LanguageToggle";
import { Button, Typography } from "@mui/joy";
import LogoIcon from "../../components/LogoIcon";

export function SignInHeader() {
  const { mode } = useColorScheme();
  return (
    <Box
      component="header"
      sx={{
        py: 3,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        variant="plain"
        color="neutral"
        component="a"
        href="https://qcsku.com/"
        sx={{ alignSelf: "center" }}
      >
        <Typography
          startDecorator={<LogoIcon size="md" dark={mode === "dark"} />}
          sx={{ fontWeight: "md" }}
        >
          QCSKU
        </Typography>
      </Button>
      {/* <ColorSchemeToggle sx={{ ml: "auto" }} /> */}
      <LanguageToggle
        size="sm"
        variant="outlined"
        sx={{ ml: 1, width: "auto" }}
      />
    </Box>
  );
}
