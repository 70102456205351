import {
  Box,
  Card,
  CardContent,
  Divider,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../../i18n";
import { useInspection } from "../hooks/useInspection";
import { useTeam } from "../../TeamLayout";
import {
  UpdateInspectionInput,
  useInspectionUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import QSupplier from "../../../../x-components/QSupplier";
import CountryFlag from "../../../../components/CountryFlag";
import SupplierRef from "../../../../components/SupplierRef";

export default function Supplier() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const { inspection } = useInspection();
  const [inspectionUpdateMutation] = useInspectionUpdateMutation({});

  const supplier = inspection?.supplier;
  const updateFn = async (updates: Omit<UpdateInspectionInput, "id">) => {
    const res = await inspectionUpdateMutation({
      variables: {
        updates: {
          id: inspection?.id,
          ...updates,
        },
      },
    });
    return res.errors?.map((err) => err.message).join("; ");
  };

  return (
    <Box
      sx={{
        gap: 2,
        p: 2,
        display: "flex",
        maxWidth: 500,
      }}
    >
      <Card variant="outlined">
        <Typography
          sx={{ width: "100%" }}
          level="title-lg"
          endDecorator={
            supplier ? (
              <SupplierRef
                copy
                openInNewWindow
                teamSlug={teamCtx.team.slug}
                supplierRef={supplier.uniqueId}
              />
            ) : null
          }
        >
          {t("Supplier")}
        </Typography>
        <Divider />
        <CardContent
          sx={{
            gap: 2,
            p: 0,
            m: 0,
          }}
        >
          <Sheet sx={{ maxHeight: "70vh", overflow: "auto" }}>
            <Table
              borderAxis="xBetween"
              sx={{
                p: 0,
                m: 0,
                "& tbody td:nth-child(1)": { width: 180 },
                "& tbody td": { pl: 0 },
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("Name")}</Typography>
                  </td>
                  <td>
                    <QSupplier
                      teamId={teamCtx.team.id}
                      supplier={inspection?.supplier ?? null}
                      update={(s) => updateFn({ supplierId: s?.id ?? null })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">
                      {t("Address Line 1")}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{supplier?.addressLine1}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">
                      {t("Address Line 2")}
                    </Typography>
                  </td>
                  <td>
                    <Typography>{supplier?.addressLine2}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("City")}</Typography>
                  </td>
                  <td>
                    <Typography>{supplier?.city}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("Postal Code")}</Typography>
                  </td>
                  <td>
                    <Typography>{supplier?.postalCode}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("Country")}</Typography>
                  </td>
                  <td>
                    {supplier?.countryCode && (
                      <CountryFlag code={supplier.countryCode} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography level="body-sm">{t("Contacts")}</Typography>
                  </td>
                  <td>
                    {supplier?.contacts?.map((contact) => (
                      <Table
                        key={contact.id}
                        borderAxis="xBetween"
                        sx={{
                          p: 0,
                          m: 0,
                          "& tbody td:nth-child(1)": { width: 40 },
                          "& tbody td": { pl: 0 },
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>{t("Name")}</td>
                            <td>{contact.name}</td>
                          </tr>
                          {contact.contactMethods?.map((method, i) => (
                            <tr key={i}>
                              <td>{method.key}</td>
                              <td>{method.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Sheet>
        </CardContent>
      </Card>
    </Box>
  );
}
