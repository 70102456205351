import Dashboard from "./Dashboard";
import DashboardLayout from "./DashboardLayout";
import CreateTeam from "./get-started/CreateTeam";
import GetStarted from "./get-started/GetStarted";
import JoinTeam from "./get-started/JoinTeam";
import DashNextSteps from "./get-started/NextSteps";
import { TeamKind } from "../../__generated__/types-and-hooks";
import EditNameModal from "./EditNameModal";
import AboutEditorModal from "./EditAboutModal";
import UserProfile from "../profile/UserProfile";
import TeamProfile from "../team-profile/TeamProfile";
import CreateApiKeyModal from "./api-keys/CreateAPIKeyModal";
import ApiKeys from "./api-keys";
import Orgs from "./organizations";
import Network from "./network";

export const dashRoutes = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "/network",
      element: <Network />,
    },
    {
      path: "/api-keys",
      element: <ApiKeys />,
      children: [
        {
          path: "/api-keys/create",
          element: <CreateApiKeyModal />,
        },
      ],
    },
    {
      path: "/org",
      element: <Orgs />,
      children: [
        {
          path: "/org/create-brand",
          element: <CreateTeam kind={TeamKind.Brand} />,
        },
        {
          path: "/org/create-agency",
          element: <CreateTeam kind={TeamKind.Agency} />,
        },
        {
          path: "/org/join",
          element: <JoinTeam />,
        },
      ],
    },
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "/edit-name",
          element: <EditNameModal open={true} />,
        },
        {
          path: "/edit-about",
          element: <AboutEditorModal open={true} />,
        },
        {
          path: "/get-started",
          element: <GetStarted open={true} />,
        },
        {
          path: "/create-brand",
          element: <CreateTeam kind={TeamKind.Brand} />,
        },
        {
          path: "/create-agency",
          element: <CreateTeam kind={TeamKind.Agency} />,
        },
        {
          path: "/join",
          element: <JoinTeam />,
        },
        {
          path: "/next-steps",
          element: <DashNextSteps text="" />,
        },
      ],
    },
    {
      path: "/user/:slug",
      element: <UserProfile />,
      children: [
        {
          path: "/user/:slug/edit-name",
          element: <EditNameModal open={true} />,
        },
        {
          path: "/user/:slug/edit-about",
          element: <AboutEditorModal open={true} />,
        },
      ],
    },
    {
      path: "/team/:teamSlug",
      element: <TeamProfile />,
    },
  ],
};
