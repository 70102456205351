import { useTranslation } from "../../../../i18n";
import { useMemo } from "react";
import AssignedIcon from "@mui/icons-material/Group";
import PrivateIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";

export function useVisibilityOptions() {
  const t = useTranslation();
  const visibilityOptions = useMemo(
    () => [
      {
        label: t("Private"),
        value: "PRIVATE",
        icon: <PrivateIcon />,
      },
      {
        label: t("Public"),
        value: "PUBLIC",
        icon: <PublicIcon />,
      },
      {
        label: t("Assigned"),
        value: "ASSIGNED",
        icon: <AssignedIcon />,
      },
    ],
    [t]
  );
  return visibilityOptions;
}
