import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { listItemButtonClasses } from "@mui/joy/ListItemButton";
import { useLocation } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { ComponentType } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { Box, Divider } from "@mui/joy";
import LanguageToggle from "../../components/header/LanguageToggle";
import ProfileDropDown from "../../components/header/ProfileDropDown";
import TeamSelect from "../../components/TeamSelect";
import { NavItem } from "../../components/Navigation";

export interface NavItemProps {
  selected: boolean;
  to: string;
  title: string;
  icon: ComponentType<{ fontSize?: any; sx?: object }>;
}

export default function Navigation() {
  const t = useTranslation();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/");

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: 0,
        overflow: "hidden auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        [`& .${listItemButtonClasses.root}`]: {
          gap: 1.5,
        },
      }}
    >
      <List
        size="sm"
        sx={{
          gap: 1,
          "--List-nestedInsetStart": { sm: "0px", lg: "30px" },
          "--ListItem-radius": (theme) => theme.vars.radius.sm,
        }}
      >
        <ListItem>
          <TeamSelect />
        </ListItem>
        <NavItem
          title={t("Dashboard")}
          selected={!section}
          to={"/"}
          icon={(props) => <DashboardIcon {...props} />}
        />
        <NavItem
          title={t("QC Network")}
          selected={section === "network"}
          to={"/network"}
          icon={(props) => <LanguageOutlinedIcon {...props} />}
        />
      </List>
      <List
        size="sm"
        sx={{
          flexGrow: 0,
          m: 1,
        }}
      >
        <LanguageToggle />
      </List>
      <Divider />
      <List
        size="sm"
        sx={{
          flexGrow: 0,
          mt: 1,
          mb: 1,
        }}
      >
        <ListItem>
          <ProfileDropDown />
        </ListItem>
      </List>
    </Box>
  );
}
