import { useOutletContext } from "react-router-dom";
import { InspectionDetailsFragment } from "../../../../__generated__/types-and-hooks";

export interface InspectionContext {
  inspection: InspectionDetailsFragment;
}

export function useInspection() {
  return useOutletContext<InspectionContext>();
}
